'use client';

import { UserMainApi } from '@ocode/domain';
import { config } from '../config/index';
import { apiResponseInterceptor, createApiHeader } from './api-interceptors';
import { getApiTokenRefresher } from './ApiTokenRefresher';

function getOrCreateApiFn() {
  const API_DEBUG = false;
  let api: UserMainApi | undefined;

  return () => {
    if (api) {
      return api;
    }
    const apiTokenRefresher = getApiTokenRefresher();
    api = new UserMainApi({
      apiBaseURL: config.apiBaseURL,
      createApiHeader,
      responseInterceptor: apiResponseInterceptor,
      errorResponseInterceptors: [apiTokenRefresher.checkAuthByRefreshToken],
      defaultErrorResponseInterceptor: apiTokenRefresher.checkAuthTokenClear,
      debug: API_DEBUG,
    });
    return api;
  };
}

const getApi = getOrCreateApiFn();

export const useApi = () => {
  return getApi();
};
